var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Konsumen"
    }
  }, [_c('b-row', [_vm.allowCreate() ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Konsumen ")], 1) : _vm._e()], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modaldetail",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "size": "lg",
      "title": "Detail Konsumen"
    },
    model: {
      value: _vm.showModaldetail,
      callback: function ($$v) {
        _vm.showModaldetail = $$v;
      },
      expression: "showModaldetail"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Tanggal")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.tanggal) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Kondisi")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.kondisi_ket) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Saldo")]), _c('p', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.form.saldo)) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Nama Toko")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.nama_toko) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Nama Owner")]), _c('p', [_vm._v(_vm._s(_vm.form.nama_owner))])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Bidang Bisnis")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.bisnis_fokus) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("No. HP Konsumen")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.no_hp) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Alamat")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.alamat) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Jenis Konsumen")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.type_konsumen ? _vm.form.type_konsumen.nama : "-") + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.tipe_bangunan) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Kepemilikan Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.kepemilikan_bangunan) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Lama Sewa Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.lama_sewa) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Keterangan")]), _c('p', [_vm._v(" " + _vm._s(_vm.form.keterangan) + " ")])])], 1)], 1), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form"
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formajuankonsumen"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PackageIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "ml-50 mb-2"
  }, [_vm._v("Data konsumen")])], 1), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isSPV ? _c('b-form-group', {
    attrs: {
      "label": "Pilih Sales",
      "label-for": "v-karyawan_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "karyawan_id",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.karyawan_id,
            "id": "v-karyawan_id",
            "name": "karyawan_id"
          },
          model: {
            value: _vm.form.karyawan_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "karyawan_id", $$v);
            },
            expression: "form.karyawan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 473597175)
  })], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen",
      "label-for": "v-pelanggan_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pelanggan_id",
      "rules": {
        required: true,
        min: 1
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.pelanggan_id,
            "id": "v-pelanggan_id",
            "name": "pelanggan_id"
          },
          model: {
            value: _vm.form.pelanggan_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "pelanggan_id", $$v);
            },
            expression: "form.pelanggan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1342656215)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Toko",
      "label-for": "v-nama_toko"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama_toko",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama_toko",
            "placeholder": "Nama Toko"
          },
          model: {
            value: _vm.form.nama_toko,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nama_toko", $$v);
            },
            expression: "form.nama_toko"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3028755319)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Owner",
      "label-for": "v-nama_owner"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama_owner",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama_owner",
            "placeholder": "Nama Owner"
          },
          model: {
            value: _vm.form.nama_owner,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nama_owner", $$v);
            },
            expression: "form.nama_owner"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 144290121)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "alamat",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Alamat"
          },
          model: {
            value: _vm.form.alamat,
            callback: function ($$v) {
              _vm.$set(_vm.form, "alamat", $$v);
            },
            expression: "form.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2743759139)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor Hp/Telepon",
      "label-for": "v-no_hp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "no_hp",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-no_hp",
            "placeholder": "no_hp",
            "type": "number"
          },
          model: {
            value: _vm.form.no_hp,
            callback: function ($$v) {
              _vm.$set(_vm.form, "no_hp", $$v);
            },
            expression: "form.no_hp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3554766528)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Binis Toko",
      "label-for": "v-bisnis_fokus"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bisnis_fokus",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bisnis_fokus",
            "placeholder": "Sembako/Restoran/Lain-lain"
          },
          model: {
            value: _vm.form.bisnis_fokus,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bisnis_fokus", $$v);
            },
            expression: "form.bisnis_fokus"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2142940696)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Bangunan",
      "label-for": "v-tipe_bangunan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tipe_bangunan",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.tipe_bangunan,
            "id": "v-tipe_bangunan",
            "name": "tipe_bangunan"
          },
          model: {
            value: _vm.form.tipe_bangunan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tipe_bangunan", $$v);
            },
            expression: "form.tipe_bangunan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2522337527)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kepemilikan Bangunan",
      "label-for": "v-kepemilikan_bangunan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kepemilikan_bangunan",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.kepemilikan_bangunan,
            "id": "v-kepemilikan_bangunan",
            "name": "kepemilikan_bangunan"
          },
          model: {
            value: _vm.form.kepemilikan_bangunan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "kepemilikan_bangunan", $$v);
            },
            expression: "form.kepemilikan_bangunan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 301195351)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lama Sewa",
      "label-for": "v-lama_sewa"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lama_sewa",
      "rules": {
        required: true,
        min: 1
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lama_sewa",
            "placeholder": "Lama Sewa"
          },
          model: {
            value: _vm.form.lama_sewa,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lama_sewa", $$v);
            },
            expression: "form.lama_sewa"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3719336010)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "keterangan",
      "rules": "required|min:2"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-keterangan",
            "placeholder": "Isi Keterangan"
          },
          model: {
            value: _vm.form.keterangan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "keterangan", $$v);
            },
            expression: "form.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 131864206)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Konsumen ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm.getStatusName(item.status) ? _c('b-badge', {
          attrs: {
            "variant": _vm.getStatusName(item.status).warna
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatusName(item.status).nama_status) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.getStatusName(item.status)) + " ")])];
      }
    }, {
      key: "cell(saldo)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.saldo)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Detail',
            expression: "'Detail'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              return _vm.detail(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), _vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.detailmodal.id,
      "title": _vm.detailmodal.title,
      "ok-only": ""
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.detailmodal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }